import React from "react";
import {
  Wrapper,
  WrapIconChat,
  IconChat,
} from "./styles";
import { shareWhatsapp } from "helpers/share-helpers";
import {WHATSAPP_NUMBER_FORMATTED} from "../../constants";

const ChatBox = () => {
  return (
    <Wrapper>
      <WrapIconChat>
        <IconChat target="_blank" href={shareWhatsapp(WHATSAPP_NUMBER_FORMATTED)}>
          <img src="/chat/whatsapp.svg" alt="Icon Chat" />
        </IconChat>
      </WrapIconChat>
    </Wrapper>
  );
};

export default ChatBox;
