import React from "react";

import MainTitle from "components/MainTitle";
import ListModels from "./components/ListModels";
import { Section, Container } from "components/Layout/styles";

function SectionModels() {
  return (
    <Section color="#F4F8FF">
      <Container>
        <MainTitle
          titleTag="h2"
          textTag="h3"
          title="Hotsites dedicados com alto poder de conversão"
          text="Crie sites dedicado para imóveis ou empreendimentos"
        />
        <ListModels
          data={[
            {
              name: "AZ10",
              site_url:
                "az10.com.br/hotsite/0cdaab24-80d3-4ad4-9e08-05859148da2e",
              imageUrl: "/hotsites/hotsite-AZ10.png",
            },
            {
              name: "Imóveis Goainésia",
              site_url:
                "imoveisgoianesia.com.br/condominioaraguaia",
              imageUrl: "/hotsites/hotsite-goianesia.png",
            },
            {
              name: "Corretora Adriana Oliveira",
              site_url:
                "corretoradrianaoliveira.com.br/hotsite/16654830-f897-4121-8983-1a5d4f89d631",
              imageUrl: "/hotsites/hotsite-corretora-adriana-oliveira.png",
            },
            {
              name: "Casa IAKO",
              site_url:
                "casaiako.com/bosc",
              imageUrl: "/hotsites/hotsite-casa-iako.png",
            },
            {
              name: "Lideral Imóveis",
              site_url:
                "lideralimoveis.com.br/hotsite/d039c15d-22f9-4180-ae04-93c474160beb",
              imageUrl: "/hotsites/hotsite-lideralimoveis.png",
            },
            {
              name: "Una Corretores Associados",
              site_url:
                "unacorretoresassociados.com.br/hotsite/8fff2ef0-8bdd-496c-9cce-7baf8752c5fd",
              imageUrl: "/hotsites/hotsite-uno.png",
            },
            {
              name: "Rota Sul Imobiliária",
              site_url:
                "rotasulbrokers.com.br/hotsite/9038bced-354f-4119-ada5-ccf76997efe9",
              imageUrl: "/hotsites/hotsite-rotasul.png",
            },
            {
              name: "Feitosa Imóveis",
              site_url:
                "imoveisfeitosa.com/hotsite/5e571001-4fb0-48b2-882d-0700007832a2",
              imageUrl: "/hotsites/hotsite-feitosa.png",
            },
          ]}
        />
      </Container>
    </Section>
  );
}

SectionModels.propTypes = {};
SectionModels.defaultProps = {};

export default SectionModels;
