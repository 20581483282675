import React from "react";
import ChatBox from "components/ChatBox";

function ContactBar() {
  return (
    <ChatBox />
  );
}

export default ContactBar;
